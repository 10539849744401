.List {
  width: 260px;
}

.ScrollbarsView {
  > * {
    min-height: 100%;
  }
}

.UserLabel.UserLabel--Self {
  font-weight: bold;
}

@font-size-base: 14px;