@import '../module-base.less';

.Container {
  border: 1px solid @normal-color;

  > :global(.ant-page-header) {
    padding: 4px 6px;
    @media (min-width: @screen-lg) {
      padding: 6px 8px;
    }
    @media (min-width: @screen-xl) {
      padding: 8px 10px;
    }
  }

  :global(.ant-page-header-heading-title) {
    min-width: 0;
    @media (min-width: @screen-lg) {
      margin-right: 16px;
    }
    @media (min-width: @screen-xl) {
      margin-right: 24px;
    }
  }

  :global(.ant-page-header-heading-sub-title) {
    overflow: initial;
    @media (min-width: @screen-lg) {
      overflow: hidden;
    }
  }

  :global(.ant-dropdown-trigger) {
    cursor: pointer;
  }

  :global(.ant-page-header-heading-extra) {
    display: none;
    @media (min-width: @screen-md) {
      display: initial;
    }
  }
}

.TitleRow {
  gap: 5px;
  @media (min-width: @screen-lg) {
    gap: 10px;
  }
  @media (min-width: @screen-xl) {
    gap: 15px;
  }
}

.Logo {
  zoom: 0.75;

  @media (min-width: @screen-lg) {
    zoom: 0.9;
  }

  @media (min-width: @screen-xl) {
    zoom: 1;
  }
}

.Title {
  overflow: hidden;
  text-overflow: ellipsis;

  font-size: 16px;
  max-width: 220px;

  @media (min-width: @screen-lg) {
    font-size: 18px;
    max-width: 260px;
  }

  @media (min-width: @screen-xl) {
    font-size: 20px;
    max-width: 320px;
  }
}

.Subtitle {
  display: none;
  @media (min-width: @screen-lg) {
    display: initial;
  }

  :global(.ant-btn) {
    :global(.anticon) + span {
      display: none;

      @media (min-width: @screen-lg) {
        display: initial;
      }
    }
  }
}

.UserLabel {
  display: flex;
  align-items: center;
  gap: 8px;

  cursor: pointer;

  > span:global(.ant-avatar) {
    margin-right: 0;
  }
}

.UserLabel__Text {
  font-weight: bold;

  display: initial;
  @media (min-width: @screen-lg) {
    display: none;
  }
  @media (min-width: @screen-xl) {
    display: initial;
  }
}

.UserDropdown {
  width: 200px;
}

.SmallPopover {
  width: 200px;

  :global(.ant-menu.ant-menu-vertical) {
    margin: 0 10px;
    border-right-width: 0;

    :global(.ant-menu-item) {
      height: 35px;
      line-height: 35px;
      margin-bottom: 0;
    }
  }

  .Divider--Sm {
    margin: 8px 10px;
  }

  .Divider--Lg {
    margin: 10px 0;
  }
}

@font-size-base: 14px;