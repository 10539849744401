.FullSize {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.Flex {
  display: flex;
}

.Flex--Center {
  align-items: center;
  justify-content: center;
}

.Flex--FullSize {
  flex: 1;
  align-self: stretch;
}

.FullWidth {
  width: 100%;
}

.FullWidth--InFlexVertical {
  align-self: stretch;
}

.ZIndex-1 {
  z-index: 1;
}

.Flex--Gap5 {
  gap: 5px;
}
