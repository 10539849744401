.IconButton {
  width: 16px;
  height: 16px;
  cursor: pointer;

  &.IconButton--Disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

@font-size-base: 14px;