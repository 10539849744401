@import '../module-base.less';
@import '../tools.less';

.Content {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.Form {
  height: 50%;
  max-height: 30vh;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.Button:global(.ant-btn) {
  height: 50px;
  line-height: 50px;
  font-size: 25px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 4px;

  @media (min-width: @screen-sm) {
    height: 60px;
    line-height: 60px;
    font-size: 30px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 6px;
  }
}

.Promo {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-wrap: wrap;
}

.PromoCard {
  padding: 20px;
  text-align: center;

  width: 100%;
  @media (min-width: @screen-sm) {
    width: 50%;
  }
  @media (min-width: @screen-lg) {
    width: 33.3%;
  }
  @media (min-width: @screen-xl) {
    width: 25%;
  }
  @media (min-width: @screen-xxl) {
    width: 20%;
  }

  > :global(.anticon) {
    color: white;
    font-size: 50px;
    margin-bottom: 10px;
  }

  > :global(.ant-typography) {
    color: white;
  }

  &:nth-child(odd) {
    background-color: @blue-7;
  }
  &:nth-child(even) {
    background-color: @green-8;
  }
}

.PromoText {
  font-size: 20px;
  line-height: 1.75;
  opacity: 0.75;
  text-align: justify;
}

@font-size-base: 14px;