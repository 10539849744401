.Panel {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 16px;
  margin-top: 10px;
  min-height: 10%;
  max-height: 100px;
  width: 260px;
}

@font-size-base: 14px;