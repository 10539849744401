@import '../module-base.less';

:global(.anticon) {
  &.DeleteButton {
    color: @red-5;
  }
}

:global(.ant-list-item) {
  &.List__Item {
    padding: 5px 0;
  }
}

:global(.ant-list) {
  &.List {
    max-height: 150px;
    overflow: auto;
    border: @border-width-base solid @border-color-base;
    padding: 10px;
    border-radius: 5px;
  }
}

@font-size-base: 14px;