* {
  box-sizing: border-box;
}

html {
  font-size: @font-size-base;
}

p {
  margin: 0;
}

body {
  font-family: Helvetica, sans-serif;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button:focus {
  outline: none;
}

div > form {
  width: 100%;
}
