@import '../module-base.less';

.Container {
  border: 1px solid @normal-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  flex-wrap: nowrap;

  padding: 3px;
  @media (min-width: @screen-md) {
    padding: 4px;
  }
  @media (min-width: @screen-lg) {
    padding: 6px;
  }
}

.Content {
  display: flex;
  overflow: hidden;
}

.Content__Inner {
  min-width: 0;
  flex: 1;
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-wrap: nowrap;
  overflow: visible;
  transition: transform 100ms ease-in-out;
}

.Column {
  &::after {
    color: @normal-color;
    content: '|';
    padding: 0 10px;
  }
  &:last-child::after {
    content: none;
  }
}

.FooterTriggerSm:global(.ant-btn) {
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.FooterPopover {
  min-width: 240px;
  width: 80vw;
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-wrap: wrap;
}

@font-size-base: 14px;