.Content {
  flex: 1;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  font-family: Helvetica, sans-serif;
  font-size: 18px;
  line-height: 1.5;

  p {
    margin-bottom: 1em;
  }
}

@font-size-base: 14px;