@import '../module-base.less';
@import '../tools.less';

.Content {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.Square() {
  flex: 1;
  border-radius: 10px;
  height: 240px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: @screen-sm) {
    min-width: 330px;
  }
  @media (min-width: @screen-md) {
    min-width: 26%;
    max-width: 33%;
  }
  @media (min-width: @screen-xl) {
    min-width: 21%;
    max-width: 25%;
  }
  @media (min-width: @screen-xxl) {
    min-width: 17%;
    max-width: 20%;
  }
}

.Button:global(.ant-btn) {
  .Square();
  flex-direction: column;
  font-size: 30px;

  > span {
    display: block;
    margin: 0 !important;
  }
}

.Card {
  .Square();
  border: 1px solid @normal-color;
  text-align: center;
  padding: 10px;
  font-size: @font-size-base;

  button {
    display: block;
    margin: 10px auto 0 auto;
  }
}

@font-size-base: 14px;