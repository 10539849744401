@import '../module-base.less';
@import '../tools.less';

.Container {
  .absolute(0);

  display: flex;
  flex-direction: column;
  padding: 10px;

  gap: 4px;
  @media (min-width: @screen-md) {
    gap: 6px;
  }
  @media (min-width: @screen-lg) {
    gap: 10px;
  }
}

.Content {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
}

.ScrollbarsView {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@font-size-base: 14px;