@import '../module-base.less';

.Container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: auto;

  gap: 8px;
  @media (min-width: @screen-lg) {
    gap: 15px;
  }
}

@collapsed-width: 24px;

.CollapsedPlaceholder {
  width: @collapsed-width;
  min-width: @collapsed-width;
}

.FormRow {
  display: flex;
  gap: 10px;

  > form {
    flex: 1;
    min-width: 240px;
    max-width: 500px;
    padding: 2px;
  }
}

.Form {
  display: flex;
  gap: 5px;

  > :first-child {
    flex: 1;
    min-width: 0;
    display: flex;
    flex-direction: column;
  }
}

.ContentRow {
  flex: 1;
  min-height: 0;
  display: flex;
  gap: 10px;
  align-items: stretch;
}

.Column {
  position: relative;
  flex: 1;
  min-width: 240px;
  max-width: 500px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  border: 1px solid @normal-color;
  border-radius: 2px;
}

.Column--Collapsed {
  flex: initial;
  min-width: @collapsed-width;
  width: @collapsed-width;

  .ListHeader {
    position: absolute;
    padding: 0;
    transform-origin: top left;
    transform: rotateZ(90deg) translateY(-100%) translateX(20px);
    border-bottom-width: 0;
    margin-bottom: 0;
    gap: 10px;
    line-height: @collapsed-width;
  }
}

.ListHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border-bottom: 1px solid @normal-color;
  padding: 4px;
  font-size: 18px;
  font-weight: bold;
  color: @green-8;
  flex-wrap: nowrap;
  white-space: nowrap;
  margin-bottom: 4px;
}

.ListHeader__Toggle {
  color: black;
  font-size: 13px;
  margin-right: 5px;
}

.Column__ScrollbarsView {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ListContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 4px;
}

.TextField {
  width: 100%;
  border-radius: 4px;
  border: 1px solid @normal-color;
  padding: 5px;

  &:focus {
    outline: 2px solid @green-3;
  }
}

.PositionAnimationWrapper {
  position: relative;
  transform: none;
}
.PositionAnimationWrapper--Active {
  transition: transform 300ms ease-in-out;
}

.Card {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 2px solid @normal-color;
  border-radius: 4px;

  > * {
    border-bottom: 1px solid @normal-color;
    &:last-child {
      border-bottom: 0;
    }
  }
}

.Card--Highlight {
  background-color: rgba(@green-4, 0.3);
}

.Card__Row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 4px 8px;
}

.Card__Owner {
  font-size: 12px;
  line-height: 1.25;
  font-weight: normal;
  color: @blue-8;
}

.Card__Owner--Redacted {
  text-decoration: line-through;
  opacity: 0.5;
}

.Card__Text {
  line-height: 1.4;
  color: @text-color;
}

.Card__ContentControls {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 40px;
  gap: 5px;
}

:global(.anticon) {
  &.Card__Control {
    cursor: pointer;
  }

  &.Card__Control--Yellow {
    color: @yellow-7;
  }
  &.Card__Control--Green {
    color: @green-8;
  }
  &.Card__Control--Blue {
    color: @blue-8;
  }
  &.Card__Control--Red {
    color: @red-6;
  }
  &.Card__Control--Magenta {
    color: @magenta-6;
  }
  &.Card__Control--Disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

:global(.ant-btn.ant-btn-sm) {
  &,
  &:active,
  &.ant-btn-icon-only {
    &.Card__Vote {
      width: 50px;
      border-width: 0;
      color: #666666;
      height: 26px;
      font-size: 15px;
    }
    &.Card__Vote--Active {
      color: black;
    }
    &.Card__Vote--Disabled {
      opacity: 0.5;
      pointer-events: none;
    }
    &.Card__Vote--ColorGreen {
      background-color: @green-5;
      &:hover {
        background-color: @green-6;
      }
    }
    &.Card__Vote--ColorRed {
      background-color: @red-4;
      &:hover {
        background-color: @red-5;
      }
    }
    &.Card__Vote--ColorNeutral {
      background-color: @cyan-3;
      &:hover {
        background-color: @cyan-4;
      }
    }
  }
}

@font-size-base: 14px;