@import '../module-base.less';

.Logo {
  font-family: Kanit, sans-serif;
  font-size: 26px;
  color: @blue-7;
  margin-top: -4px;
  cursor: pointer;
}

@font-size-base: 14px;