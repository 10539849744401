@import '../tools.less';
@import '../module-base.less';

.Content:global(.ant-layout) {
  gap: 10px;
  background-color: white;
}

.Content--Right {
  display: flex;
  flex-direction: column;

  gap: 5px;
  @media (min-width: @screen-lg) {
    gap: 10px;
  }
}

.ParticipantsIcon {
  font-size: 18px;
}

.ParticipantsBlock {
  width: 280px;
  min-width: 280px;
  display: flex;
  flex-direction: column;
}

.ControlsBlock {
  height: auto;
  border: 1px solid @normal-color;
  padding: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  gap: 5px;
  @media (min-width: @screen-lg) {
    gap: 10px;
  }

  :global(.ant-btn) > :global(.anticon) + span {
    display: none;
    @media (min-width: @screen-md) {
      display: initial;
    }
  }

  :global(.ant-select-selection-item) .Select__LabelText {
    display: none;
    @media (min-width: @screen-md) {
      display: initial;
    }
  }
}

.ControlsToggle {
  position: relative;
  align-self: flex-end;
}

.ColumnsBlock {
  flex: 1;
  min-height: 0;
  display: flex;
  align-items: stretch;
}

@font-size-base: 14px;